import type {OverviewPayload} from '@github-ui/code-view-types'
import {useFeatureFlag} from '@github-ui/react-core/use-feature-flag'
import type {SafeHTMLString} from '@github-ui/safe-html'
import {SafeHTMLBox} from '@github-ui/safe-html'
import safeStorage from '@github-ui/safe-storage'
import {useIsPlatform} from '@github-ui/use-is-platform'
import {useNavigate} from '@github-ui/use-navigate'
import {verifiedFetch} from '@github-ui/verified-fetch'
import {DesktopDownloadIcon, FileZipIcon, QuestionIcon, TerminalIcon} from '@primer/octicons-react'
import {ActionList, Box, Button, Flash, Heading, Link, Spinner, Text, Textarea, UnderlineNav} from '@primer/react'
import {TabNav} from '@primer/react/deprecated'
import {Tooltip} from '@primer/react/next'
import type React from 'react'
import {useCallback, useEffect, useState} from 'react'
import {CodeMenuButton} from '@github-ui/code-dropdown-button/components/CodeMenuButton'
import {CloneUrl} from '@github-ui/code-dropdown-button/components/LocalTab'

import {useReposAppPayload} from '@github-ui/code-view-shared/contexts/FilesPageInfoContext'

const safeLocalStorage = safeStorage('localStorage')

interface CodeButtonProps {
  refName: string
  repoId: number
  primary: boolean
  payload: OverviewPayload
  isLoggedIn: boolean

  // Used to create a Copilot Workspace link (which is currently behind a feature flag)
  repoOwner?: string
  repoName?: string
}

interface ErrorState {
  header?: string
  message?: React.ReactNode
}

const ActiveTab = {
  Local: 'local',
  Codespaces: 'cloud',
  Copilot: 'copilot',
} as const

type ActiveTab = (typeof ActiveTab)[keyof typeof ActiveTab]

export function CodeButton(props: CodeButtonProps) {
  const {isLoggedIn, refName, repoId, repoOwner, repoName, payload} = props
  const localStorageDefaultTabKey = 'code-button-default-tab'
  const localStorageCopilotWorkspaceKey = `copilot-workspace-task:${repoOwner}/${repoName}`
  const [activeTab, setActiveTab] = useState<string>(ActiveTab.Local)
  const [codespacesContent, setCodespacesContent] = useState('')
  const [codespacesLoading, setCodespacesLoading] = useState(false)
  const [errorState, setErrorState] = useState<ErrorState>({})
  const [openingPlatform, setOpeningPlatform] = useState('')
  const {
    codespacesEnabled,
    hasAccessToCodespaces,
    repoPolicyInfo,
    contactPath,
    currentUserIsEnterpriseManaged,
    enterpriseManagedBusinessName,
    isEnterprise,
    newCodespacePath,
  } = payload.codeButton
  const {defaultProtocol} = payload.codeButton.local.protocolInfo
  const [activeLocalTab, setActiveLocalTab] = useState(defaultProtocol)

  const onCodespacesTabClick = useCallback(
    async (ev?: React.MouseEvent) => {
      setActiveTab(ActiveTab.Codespaces)
      safeLocalStorage.setItem(localStorageDefaultTabKey, ActiveTab.Codespaces)
      ev?.preventDefault()
      if (!isLoggedIn) {
        setErrorState({
          header: 'Sign In Required',
          message: (
            <span>
              Please{' '}
              <Link inline href={newCodespacePath}>
                sign in
              </Link>{' '}
              to use Codespaces.
            </span>
          ),
        })
        return
      }
      if (codespacesContent || codespacesLoading || !repoPolicyInfo) {
        return
      }
      const defaultErrorMessage = (
        <span>
          An unexpected error occurred. Please{' '}
          <Link inline href={contactPath}>
            contact support
          </Link>{' '}
          for more information.
        </span>
      )
      if (hasAccessToCodespaces) {
        setCodespacesLoading(true)

        const encodeRef = encodeURIComponent(refName)

        const result = await verifiedFetch(
          `/codespaces?codespace%5Bref%5D=${encodeRef}&current_branch=${encodeRef}&event_target=REPO_PAGE&repo=${repoId}`,
        )

        if (result.ok) {
          const data = await result.text()
          setCodespacesContent(data)
        } else {
          setErrorState({
            header: 'Codespace access limited',
            message: defaultErrorMessage,
          })
        }
        setCodespacesLoading(false)
      } else {
        let header
        let message
        if (!repoPolicyInfo.allowed) {
          header = 'Codespace access limited'
          if (!repoPolicyInfo.canBill && currentUserIsEnterpriseManaged) {
            message = (
              <span>
                <Link href="https://docs.github.com/enterprise-cloud@latest/admin/identity-and-access-management/using-enterprise-managed-users-for-iam/about-enterprise-managed-users">
                  Enterprise-managed users
                </Link>
                {` must have their Codespaces usage paid for by ${
                  enterpriseManagedBusinessName || 'their enterprise'
                }.`}
              </span>
            )
          } else if (repoPolicyInfo.hasIpAllowLists) {
            message = (
              <span>
                Your organization or enterprise enforces{' '}
                <Link
                  inline
                  href="https://docs.github.com/enterprise-cloud@latest/organizations/keeping-your-organization-secure/managing-security-settings-for-your-organization/managing-allowed-ip-addresses-for-your-organization"
                >
                  IP allow lists
                </Link>
                Which are unsupported by Codespaces at this time.
              </span>
            )
          } else if (repoPolicyInfo.disabledByBusiness) {
            message = (
              <span>
                Your enterprise has disabled Codespaces at this time. Please contact your enterprise administrator for
                more information.
              </span>
            )
          } else if (repoPolicyInfo.disabledByOrganization) {
            message = (
              <span>
                Your organization has disabled Codespaces on this repository. Please contact your organization
                administrator for more information.
              </span>
            )
          } else {
            message = defaultErrorMessage
          }
        } else if (!repoPolicyInfo.changesWouldBeSafe) {
          header = 'Repository access limited'
          message = <span>You do not have access to push to this repository and its owner has disabled forking.</span>
        } else {
          header = 'Codespace access limited'
          message = defaultErrorMessage
        }
        setErrorState({header, message})
      }
    },
    [
      codespacesContent,
      codespacesLoading,
      contactPath,
      currentUserIsEnterpriseManaged,
      enterpriseManagedBusinessName,
      hasAccessToCodespaces,
      isLoggedIn,
      newCodespacePath,
      refName,
      repoId,
      repoPolicyInfo,
    ],
  )

  const onLocalTabClick = useCallback((ev?: React.MouseEvent) => {
    setActiveTab(ActiveTab.Local)
    safeLocalStorage.setItem(localStorageDefaultTabKey, ActiveTab.Local)
    ev?.preventDefault()
  }, [])

  const onCopilotTabClick = useCallback((ev?: React.MouseEvent) => {
    setActiveTab(ActiveTab.Copilot)
    safeLocalStorage.setItem(localStorageDefaultTabKey, ActiveTab.Copilot)
    ev?.preventDefault()
  }, [])

  useEffect(() => {
    const defaultActiveTab = safeLocalStorage.getItem(localStorageDefaultTabKey)
    if (defaultActiveTab === ActiveTab.Codespaces && codespacesEnabled) {
      onCodespacesTabClick()
    }
    // Only run after the initial render.
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onOpenChange = useCallback((open: boolean) => {
    if (!open) {
      setOpeningPlatform('')
    }
  }, [])

  const tabLinkStyles = {
    height: '40px',
    width: '50%',
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderTop: 0,
    color: 'fg.muted',
    ':hover': {
      backgroundColor: 'unset',
      borderColor: 'border.default',
    },
    '&.selected': {
      backgroundColor: 'canvas.overlay',
    },
    ':not(&.selected)': {
      border: 0,
    },
  }

  const copilotWorkspaceEnabled = useFeatureFlag('copilot_workspace')

  const openTaskInCopilotWorkspace = useCallback(
    (task: string) => {
      window.open(
        `https://copilot-workspace.githubnext.com/${repoOwner}/${repoName}?task=${encodeURIComponent(
          task,
        )}&branch=${encodeURIComponent(refName)}`,
        '_self',
      )
    },
    [repoOwner, repoName, refName],
  )

  const [copilotWorkspaceTask, setCopilotWorkspaceTask] = useState(() => {
    return safeLocalStorage.getItem(localStorageCopilotWorkspaceKey) || ''
  })

  return (
    <CodeMenuButton onOpenChange={onOpenChange} isPrimary={props.primary}>
      {openingPlatform === 'githubDesktop' ? (
        <LaunchingPlatformContents platform="GitHub Desktop" href="https://desktop.github.com/" />
      ) : openingPlatform === 'visualStudio' ? (
        <LaunchingPlatformContents platform="Visual Studio" />
      ) : openingPlatform === 'xcode' ? (
        <LaunchingPlatformContents platform="Xcode" href="https://developer.apple.com/xcode/" />
      ) : (
        <div className={'react-overview-code-button-action-list py-0'}>
          {!isEnterprise && codespacesEnabled && (
            <TabNav>
              <TabNav.Link
                as={Button}
                selected={activeTab === ActiveTab.Local}
                onClick={onLocalTabClick}
                sx={{
                  ...tabLinkStyles,
                  borderLeft: 0,
                }}
              >
                Local
              </TabNav.Link>
              <TabNav.Link
                as={Button}
                selected={activeTab === ActiveTab.Codespaces}
                onClick={onCodespacesTabClick}
                sx={{
                  ...tabLinkStyles,
                  borderRight: copilotWorkspaceEnabled ? null : 0,
                }}
              >
                Codespaces
              </TabNav.Link>
              {copilotWorkspaceEnabled && (
                <TabNav.Link
                  as={Button}
                  selected={activeTab === ActiveTab.Copilot}
                  onClick={onCopilotTabClick}
                  sx={{
                    ...tabLinkStyles,
                    borderRight: 0,
                  }}
                >
                  Copilot
                </TabNav.Link>
              )}
            </TabNav>
          )}
          {activeTab === 'local' && (
            <LocalTab
              payload={payload}
              setOpeningPlatform={setOpeningPlatform}
              activeLocalTab={activeLocalTab}
              setActiveLocalTab={setActiveLocalTab}
            />
          )}
          {activeTab === 'cloud' && (
            <CodespacesTab errorState={errorState} loading={codespacesLoading} content={codespacesContent} />
          )}
          {activeTab === ActiveTab.Copilot && copilotWorkspaceEnabled && (
            <Box
              as="form"
              sx={{p: 3, display: 'grid', gap: 3}}
              onSubmit={ev => {
                ev.preventDefault()

                // Clear local storage
                safeLocalStorage.removeItem(localStorageCopilotWorkspaceKey)

                const formData = new FormData(ev.currentTarget)
                const task = formData.get('task') as string
                openTaskInCopilotWorkspace(task)
              }}
            >
              <Textarea
                name="task"
                aria-label="Task"
                placeholder="Describe a task..."
                resize="vertical"
                block
                value={copilotWorkspaceTask}
                onChange={ev => {
                  setCopilotWorkspaceTask(ev.target.value)
                  safeLocalStorage.setItem(localStorageCopilotWorkspaceKey, ev.target.value)
                }}
                onKeyDown={ev => {
                  // eslint-disable-next-line @github-ui/ui-commands/no-manual-shortcut-logic
                  if (ev.key === 'Enter' && (ev.metaKey || ev.ctrlKey)) {
                    ev.preventDefault()

                    // Clear local storage
                    safeLocalStorage.removeItem(localStorageCopilotWorkspaceKey)

                    const task = ev.currentTarget.value
                    openTaskInCopilotWorkspace(task)
                  }
                }}
              />
              <Button type="submit" variant="primary" block>
                Start task
              </Button>
            </Box>
          )}
        </div>
      )}
    </CodeMenuButton>
  )
}

function LocalTab({
  payload,
  setOpeningPlatform,
  activeLocalTab,
  setActiveLocalTab,
}: {
  payload: OverviewPayload
  setOpeningPlatform: React.Dispatch<React.SetStateAction<string>>
  activeLocalTab: string
  setActiveLocalTab: React.Dispatch<React.SetStateAction<string>>
}) {
  const {helpUrl} = useReposAppPayload()
  const {
    httpAvailable,
    sshAvailable,
    httpUrl,
    showCloneWarning,
    sshUrl,
    sshCertificatesRequired,
    sshCertificatesAvailable,
    ghCliUrl,
    newSshKeyUrl,
    setProtocolPath,
  } = payload.codeButton.local.protocolInfo

  const {cloneUrl, visualStudioCloneUrl, showVisualStudioCloneButton, showXcodeCloneButton, xcodeCloneUrl, zipballUrl} =
    payload.codeButton.local.platformInfo
  const isMacOrWindows = useIsPlatform(['windows', 'mac'])
  const isMac = useIsPlatform(['mac'])
  const navigate = useNavigate()

  const localProtocolClassName = 'mt-2 fgColor-muted text-normal'

  const onProtocolTabClick = useCallback(
    (protocol: string) => {
      if (activeLocalTab !== protocol) {
        setActiveLocalTab(protocol)
        const formData = new FormData()
        formData.set('protocol_selector', protocol)
        verifiedFetch(setProtocolPath, {method: 'post', body: formData})
      }
    },
    [activeLocalTab, setActiveLocalTab, setProtocolPath],
  )

  return (
    <div>
      <Box sx={{m: 3}}>
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          <TerminalIcon className="mr-2" />
          <Text sx={{flexGrow: 1, fontWeight: 'bold'}}>Clone</Text>
          <Tooltip text="Which remote URL should I use?" type="label" direction="w">
            <Link muted href={`${helpUrl}/articles/which-remote-url-should-i-use`}>
              <QuestionIcon className="mr-1" />
            </Link>
          </Tooltip>
        </Box>
        <UnderlineNav sx={{border: 'none', my: 2, px: 0}} aria-label="Remote URL selector">
          {httpAvailable && (
            <UnderlineNav.Item
              aria-current={activeLocalTab === 'http' ? 'page' : undefined}
              sx={{fontWeight: 'bold'}}
              onClick={ev => {
                onProtocolTabClick('http')
                ev.preventDefault()
              }}
            >
              HTTPS
            </UnderlineNav.Item>
          )}
          {sshAvailable && (
            <UnderlineNav.Item
              aria-current={activeLocalTab === 'ssh' ? 'page' : undefined}
              sx={{fontWeight: 'bold'}}
              onClick={ev => {
                onProtocolTabClick('ssh')
                ev.preventDefault()
              }}
            >
              SSH
            </UnderlineNav.Item>
          )}
          <UnderlineNav.Item
            aria-current={activeLocalTab === 'gh_cli' ? 'page' : undefined}
            sx={{fontWeight: 'bold'}}
            onClick={ev => {
              onProtocolTabClick('gh_cli')
              ev.preventDefault()
            }}
          >
            GitHub CLI
          </UnderlineNav.Item>
        </UnderlineNav>
        {activeLocalTab === 'http' ? (
          <>
            <CloneUrl inputId="clone-with-https" inputLabel="Clone with HTTPS url" url={httpUrl} />
            <p className={localProtocolClassName}>Clone using the web URL.</p>
          </>
        ) : activeLocalTab === 'ssh' ? (
          <>
            {showCloneWarning && (
              <Flash sx={{mb: 2}} variant="warning">
                {"You don't have any public SSH keys in your GitHub account. "}
                You can{' '}
                <Link inline href={newSshKeyUrl}>
                  add a new public key
                </Link>
                , or try cloning this repository via HTTPS.
              </Flash>
            )}
            <CloneUrl inputId="clone-with-ssh" inputLabel="Clone with SSH url" url={sshUrl} />
            <p className={localProtocolClassName}>
              {sshCertificatesRequired
                ? 'Use a password-protected SSH certificate.'
                : sshCertificatesAvailable
                  ? 'Use a password-protected SSH key or certificate.'
                  : 'Use a password-protected SSH key.'}
            </p>
          </>
        ) : (
          <>
            <CloneUrl inputId="clone-with-gh-cli" inputLabel="Clone with GitHub CLI command" url={ghCliUrl} />
            <p className={localProtocolClassName}>
              Work fast with our official CLI.{' '}
              <Link inline href="https://cli.github.com" target="_blank" aria-label="Learn more about the GitHub CLI">
                Learn more
              </Link>
            </p>
          </>
        )}
      </Box>
      <ActionList variant="inset" className="border-top">
        {isMacOrWindows && (
          <ActionList.Item
            onSelect={() => {
              setOpeningPlatform('githubDesktop')
              // Perform the navigation in an onClick because ActionList.LinkItems
              // close the overlay when clicked.
              navigate(cloneUrl)
            }}
          >
            <ActionList.LeadingVisual>
              <DesktopDownloadIcon />
            </ActionList.LeadingVisual>
            Open with GitHub Desktop
          </ActionList.Item>
        )}
        {isMacOrWindows && showVisualStudioCloneButton && (
          <ActionList.Item
            onSelect={() => {
              setOpeningPlatform('visualStudio')
              navigate(visualStudioCloneUrl)
            }}
          >
            Open with Visual Studio
          </ActionList.Item>
        )}
        {isMac && showXcodeCloneButton && (
          <ActionList.Item
            onSelect={() => {
              setOpeningPlatform('xcode')
              navigate(xcodeCloneUrl)
            }}
          >
            Open with Xcode
          </ActionList.Item>
        )}
        <ActionList.LinkItem data-turbo="false" href={zipballUrl} rel="nofollow">
          <ActionList.LeadingVisual>
            <FileZipIcon />
          </ActionList.LeadingVisual>
          Download ZIP
        </ActionList.LinkItem>
      </ActionList>
    </div>
  )
}

function CodespacesTab({errorState, loading, content}: {errorState: ErrorState; loading: boolean; content: string}) {
  return (
    <Box sx={{display: 'flex', justifyContent: 'center'}}>
      {loading ? (
        <Spinner sx={{margin: 2}} />
      ) : errorState.header && errorState.message ? (
        <Box sx={{p: 4, m: '40px'}}>
          <Heading as="h4" sx={{fontSize: '16px', textAlign: 'center'}}>
            {errorState.header}
          </Heading>
          <Box sx={{textAlign: 'center'}}>{errorState.message}</Box>
        </Box>
      ) : (
        <SafeHTMLBox sx={{width: '100%'}} html={content as SafeHTMLString} />
      )}
    </Box>
  )
}

function LaunchingPlatformContents({platform, href}: {platform: string; href?: string}) {
  return (
    <Box sx={{width: '400px', p: 3}}>
      <Heading as="h4" sx={{fontSize: '16px', textAlign: 'center', mb: 3}}>
        {`Launching ${platform}`}
      </Heading>
      {href && (
        <Text sx={{mb: 3}}>
          If nothing happens, <Link inline href={href}>{`download ${platform}`}</Link> and try again.
        </Text>
      )}
    </Box>
  )
}

try{ CodeButton.displayName ||= 'CodeButton' } catch {}
try{ LocalTab.displayName ||= 'LocalTab' } catch {}
try{ CodespacesTab.displayName ||= 'CodespacesTab' } catch {}
try{ LaunchingPlatformContents.displayName ||= 'LaunchingPlatformContents' } catch {}